import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './custom_css/About.css'


export default function AboutUs() {

    return(
        <div className="container">
            <h1 className="head-about">About Us</h1>
            <div>
                <h3 className='headings'>History</h3>
                <p>
                The Bachelor of Science in Packaging Technology at Kwame Nkrumah University 
                of Science and Technology (KNUST) represents a significant leap forward in 
                the field of packaging education. Since its establishment in 2021, the program 
                has quickly risen to prominence, amassing a student body of 600, a clear testament 
                to its growing relevance and appeal within and beyond Ghana. The program's structure, 
                which encompasses four distinct specializations, is tailored to address 
                the multifaceted nature of the packaging industry. This comprehensive educational 
                offering not only equips students with a broad understanding of packaging technology 
                but also allows them to delve deeply into specific areas of interest, thus preparing 
                them for the nuanced challenges and opportunities of the global market.

                </p>
            </div>
            
            <div>
                <h5 clasaName='headings' >Food Packaging </h5>
                <p>
                Food Packaging* is a critical specialization that focuses on the development and 
                application of packaging solutions designed to ensure the safety, longevity, and 
                quality of food products. This stream is particularly important in a world grappling
                with issues of food security, sustainability, and the global supply chain's intricacies. 
                Students in this specialization learn about the science behind food preservation, innovative
                materials that can enhance shelf life, and the regulatory environment governing food packaging 
                on a global scale.
                </p>

            </div>
            <div>
                <h5 className='headings'>Health Packaging</h5>

                <p>
                Pharmaceutical Packaging addresses the unique challenges associated with packaging 
                for the pharmaceutical industry. This specialization is crucial, given the stringent 
                standards for safety, efficacy, and compliance that characterize the sector. Students
                explore topics such as barrier properties, sterilization techniques, and tamper-evident 
                packaging designs. Moreover, they are introduced to the latest innovations in smart packaging, 
                which can improve patient compliance and ensure the integrity of pharmaceutical products 
                throughout their lifecycle.

                </p>
            </div>
            <div>
                <h5 className='headings'>Package Design and Graphics</h5>
                <p>
                Package Design and Graphics stands at the intersection of creativity and technology, 
                focusing on the aesthetics and functional design of packaging. This specialization is geared 
                towards those interested in the visual and physical aspects of packaging that influence consumer 
                behavior and brand perception. Students learn about the principles of design, color theory, typography,
                 and the use of software tools for creating compelling packaging. This area emphasizes the importance of 
                 sustainability and user experience in design, preparing students to develop packaging that is not only 
                 attractive but also environmentally friendly and user-centric.

                </p>
            </div>
            <div>
                <h5 className='headings'>Packaging Value Chain</h5>
                <p>
                Packaging Value Chain offers a holistic view of the packaging industry, emphasizing the 
                interconnectedness of various stages in the packaging lifecycle, from material sourcing and 
                manufacturing to distribution, use, and end-of-life management. This specialization is designed 
                for those looking to understand and optimize the packaging process as a whole. Students gain insights
                into supply chain management, logistics, quality control, and the economic aspects of packaging. This 
                broad perspective is crucial for identifying efficiencies, minimizing waste, and enhancing the sustainability of 
                packaging operations.

                </p>
            </div>
            <div>
                <h5 className='headings' >Accomplishments</h5>
                <p>
                The accolades received by the BSc Packaging Technology program at KNUST are a testament to its excellence.
                The program's achievements, including winning the Gold Star for the Save the Food category in the 2022 National 
                and Global Awards and securing four more awards in 2024, including the Overall Best Package (Gold), reflect its 
                commitment to innovation, sustainability, and global best practices. These accolades are not just a recognition of 
                the program's current success but also a strong foundation for future endeavors in the ever-evolving field of packaging technology. <br/><br/>

                 <p>
                In summary, the BSc Packaging Technology at KNUST stands out as a beacon of excellence and innovation in higher 
                education. Its comprehensive curriculum, distinguished by four specialized areas of study, prepares students to 
                tackle the challenges of the modern packaging industry head-on. Through a blend of theoretical knowledge and practical 
                experience, supported by a faculty of experts, students are groomed to become leaders in their fields. The program's 
                remarkable string of achievements in its relatively short existence underscores its quality and the pivotal role it is
                 poised to play in shaping the future of packaging technology globally.
                 </p>
                </p>
            </div>

        </div>
    )
}