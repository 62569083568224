import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './custom_css/FacultyStaff.css'

import Afrifa from '../images/patrons/Afrifa_Kojo.jpg'
import Rogerson from '../images/patrons/Rogerson_Anokye.jpg'
import Jonny from '../images/patrons/Jonny_Osei_Kofi.jpg'
import Frimpnog from '../images/patrons/Prof K Frimpong Mensah.jpg'
import Boadu from '../images/patrons/Kwadwo_Boakye_Boadu.jpg'
import Charles from '../images/patrons/Charles_Antwi_Boasiako (2).jpg'


export default function FalcultyAndStaff() {

    return(
        <div className='container'>
            <div className='info-about-FacultyStaff'>

            <h1 className='faculty-staff'>Faculty and Staff</h1> 
            <p className='faculty-staff-content'>The faculty and staff of a Department of Wood Science and Technology typically comprise a diverse group of professionals 
                dedicated to advancing the field through education, research, and outreach. These individuals may possess a wide range of 
                expertise in areas such as wood properties and processing, sustainable forestry practices, bio-based materials, wood design and 
                construction technologies, and the environmental impacts of wood products.
            
            Faculty members usually hold advanced degrees in wood science, forestry, materials science, or related fields. They are 
            often involved in cutting-edge research projects aimed at improving the sustainability, durability, and functionality of 
            wood and wood-based materials. Their work might explore innovative uses of wood in construction, the development of new wood 
            composites, or the environmental benefits of wood as a renewable resource.
            
            Staff members provide essential support to the department's mission, handling administrative duties, laboratory management, 
            technical support for research projects, and student services. They ensure the smooth operation of the department and contribute 
            to the creation of a supportive learning and research environment.
            
            Together, the faculty and staff of a Department of Wood Science and Technology work to educate the next generation of wood
             scientists and industry professionals, advance knowledge in the field, and promote the sustainable use of wood resources worldwide.</p>
             </div>
            


            {/*More from patsa community*/}
            <h3 id="more-from-patsa">Faculty and Staff</h3>

            <div className="container">
                <div className="row"> 

                {/*Card content*/}

                
                    {/*Prof.Afrifa*/}
                    <div className="col-md-4" id="patrons">
                        <img src={Afrifa} alt="Prof. Afrifa" className="img-fluid"/>
                        <h4>Prof. Kojo Agyapong Afrifah</h4>
                        <p>Head of Department</p>
                        <p>Wood Science Technology</p>
                    </div>

                    {/*Dr.Rogerson*/}
                    <div  className="col-md-4" id="patrons">
                        <img src={Rogerson} alt="Dr. Rogerson" className="img-fluid"/>
                        <h3>Dr. Rogerson Anokye</h3>
                        <p></p>
                        <p>Wood Science Technology</p>
                    </div>

                    {/*Mr.Jonny*/}
                    <div  className="col-md-4" id="patrons">
                        <img src={Jonny} alt="Jonny" className="img-fluid"/>
                        <h3>Mr. Jonny Osei Kofi</h3>
                        <p></p>
                        <p>Wood Science Technology</p>
                    </div>

                    {/*Dr. Boadu*/}
                    <div  className="col-md-4" id="patrons">
                        <img src={Frimpnog} alt="Frimpong" className="img-fluid"/>
                        <h3>Prof. K. Frimpong-Mensah</h3>
                        <p></p>
                        <p>Wood Science Technology</p>
                    </div>
                

                    {/*Dr. Boadu*/}
                    <div  className="col-md-4" id="patrons">
                        <img src={Boadu} alt="Boadu" className="img-fluid"/>
                        <h3>Dr. Kwadwo Boakye Boadu</h3>
                        <p></p>
                        <p>Wood Science Technology</p>
                    </div>
                
                        {/*Prof. Charles*/}
                    <div  className="col-md-4" id="patrons">
                        <img src={Charles} alt="Charles" className="img-fluid"/>
                        <h3>Prof. Charles Antwi Boasiako(Late)</h3>
                        <p></p>
                        <p>Wood Science Technology</p>
                    </div>
                </div>
        </div>

        </div>
        
    )
}