
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import MainContent from './MainContent';
import Carousel from './Carousel';
import Sponsors from './Sponsors';


export default function Home() {
  return(
    <div>
      <Carousel/>
      <MainContent/>
      <Sponsors/>
    </div>
  )
}