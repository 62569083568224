import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

//custome carousel style

import './custom_css/Carousel.css'

import banner from '../images/carousel/banner.jpg'
import banner2 from '../images/carousel/banner2.jpg'
import banner3 from '../images/carousel/banner3.jpg'



export default function Carousel() {

    return(
      <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={banner} className="d-block w-100" alt="..."/>
        </div>
        <div className="carousel-item">
          <img src={banner2} className="d-block w-100" alt="..."/>
        </div>
        <div className="carousel-item">
          <img src={banner3} className="d-block w-100" alt="..."/>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    )
}