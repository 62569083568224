//this is home page Maincontent

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './custom_css/MainContent.css'
import PurposefulContent from './Purposeful_content';
import './custom_css/Sponsors.css'

//React Router
import { Link } from 'react-router-dom';

// images for card
import im1 from '../images/front_page_img/im1.jpg'
import im2 from '../images/front_page_img/im2.jpg'
import im3 from '../images/front_page_img/im3.jpg'
import im4 from '../images/front_page_img/im4.jpg'
import im5 from '../images/front_page_img/im5.jpg'
import im6 from '../images/front_page_img/im6.jpg'
import sponsors from '../images/sponsors/sponsors.jpg'

import knustPackaging from '../images/KNUST packaging logo/Knust Packaging technology.jpg'



export default function MainContent() {

    return(
        
        <div>
                        {/*KNUST and packaging logo*/}
            <div className='container'>

                    <img  className='KnustPacklogo'src={knustPackaging} alt="Knust Packaging"/>

            </div>

            <PurposefulContent/>

            {/*More from patsa community*/}
            <h2 id="more-from-patsa">MORE FROM PATSA COMMUNITY</h2>

            <div className="container">
                <div className="row"> 

                {/*Card content*/}

                
                    <div className="col-md-4" id="patrons">
                        <img src={im1} alt="im1" className="img-fluid"/>
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={im2} alt="im2" className="img-fluid"/>

                    </div>


                    <div  className="col-md-4" id="patrons">
                        <img src={im3} alt="im3" className="img-fluid"/>
                    </div>


                    <div  className="col-md-4" id="patrons">
                        <img src={im4} alt="im4" className="img-fluid"/>
                        
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={im5} alt="im5" className="img-fluid"/>
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={im6} alt="im6" className="img-fluid"/>
                    </div>
                </div>
            </div>
            {/*sponors logo*/}
            <div className='sponsors-text-img'>
                <h3 className='sponsors-text'>Sponsors</h3>
                <img className='sponsors-img'src={sponsors} alt='sponsors'/>
            </div>
        </div>
    )
}