import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Award1 from '../images/Achievements/award 1.jpg';
import Award2 from '../images/Achievements/award 2.jpg';
import Award3 from '../images/Achievements/award 3.jpg';
import Award4 from '../images/Achievements/award 4.jpg';
import Award5 from '../images/Achievements/award 5.jpg';
import Acoustic from '../images/Executives/Acoustic.jpg';
import Ada from '../images/Executives/Ada.jpg';
import Baiden from '../images/Executives/Baiden.jpg';
import Man from '../images/Executives/Man.jpg';
import Saji from '../images/Executives/Saji.jpg';
import Solomon from '../images/Executives/Solo.jpg';
import woman2 from '../images/Executives/woman 2.jpg';
import woman from '../images/Executives/woman.jpg';
import './custom_css/Association.css';
import Adubofour from '../images/Executives/Adubofour.jpg'
import Man2 from '../images/Executives/Man2.jpg'
import Man3 from '../images/Executives/Man3.jpg'

{/*Achievement Images*/}
{/*Executives Images*/}



export default function Association() {

    return(
        <div className="container">
            <h1 className="head-association">Association</h1>
            <div>
                <h5 className='headings'>A Purposeful Association</h5>
                <p className='content'>
                BSc. Packaging Technology was founded 4 years ago on a bedrock of societal purpose. 
                Our mission is to contribute to the world by educating students, people, Companies 
                for lives of leadership and purposeful contribution; advancing fundamental knowledge
                and cultivating creativity; and accelerating solutions and amplifying their impact on packaging.
                </p>
            </div>
            <div>
                <h5 className='headings'>Achievements</h5>
                <p className='content'>
                <ol>
                    <li>
                        Winners of the 2022 National and Global Awards in the international students awards organized by royal crown and world packaging organization, winning a Gold star for save the Food category.<br/><br/>
                    </li>
                    <li>
                        Winner of the 2024 national and Global awards. Winning a total of four awards including the overall best package (Gold), making a total sum of five awards in 2024 The program has accumulated Seven Awards within a period of four years.<br/><br/>
                    </li>
               
                </ol>
                {/*Achievement content*/}
                <div className="container">
                <div className="row"> 

                    <div className="col-md-4" id="patrons">
                        <img src={Award1} alt="im1" className="img-fluid"/>
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={Award2} alt="im2" className="img-fluid"/>

                    </div>


                    <div  className="col-md-4" id="patrons">
                        <img src={Award3} alt="im3" className="img-fluid"/>
                    </div>


                    <div  className="col-md-4" id="patrons">
                        <img src={Award4} alt="im4" className="img-fluid"/>
                        
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={Award5} alt="im5" className="img-fluid"/>
                    </div>
                </div>
            </div><br/><br/>


            {/*Executives*/}

                <h2>Executives</h2>
           
                
                As executives at the forefront of the packaging technology industry, we are thrilled to share with you the 
                innovative strides our program is making towards redefining packaging solutions. In a world where the demand
                for sustainable, efficient, and smart packaging is ever-increasing, we are committed to pushing the boundaries of what's possible.<br/><br/>
                
                Our team of experts is dedicated to harnessing the latest advancements in Materials Science, Engineering,
                and Digital Technology to develop packaging that not only meets but exceeds the expectations of consumers and 
                industries alike. From eco-friendly materials that reduce environmental impact to smart packaging that enhances 
                user experience, we are at the cutting edge of creating solutions that are both practical and revolutionary. <br/><br/>
                
                We believe that packaging is not just about containment and protection; it's about communication, convenience, 
                and contributing positively to the global sustainability agenda. Our focus is on innovation that matters, delivering 
                value to our customers, and making a difference in the world. <br/><br/>
                
                We invite you to explore our website and discover the wide range of packaging technologies we offer. Whether you're 
                looking for insights into the future of packaging, innovative solutions for your business, or opportunities to collaborate, 
                you'll find that our commitment to excellence is the driving force behind everything we do. <br/><br/>
                
                Thank you for visiting. We look forward to engaging with you and exploring how our packaging technologies can help achieve your goals.
                </p>
                
                
                {/*Executive Card content*/}
                
                <div className="container">
                <div className="row"> 



                
                    <div className="col-md-4" id="executives">
                        <img src={Baiden} alt="im1" className="img-fluid"/>
                        <h3>Adu-Baiden Martin</h3>
                        <h5>President</h5>

                    </div>

                    <div  className="col-md-4" id="executives">
                        <img src={Saji} alt="im2" className="img-fluid"/>
                        <h3>Sali Saji</h3>
                        <h5>Vice President</h5>
                    </div>

                    <div  className="col-md-4" id="executives">
                        <img src={Solomon} alt="im2" className="img-fluid"/>
                        <h3>Ewool Solomon</h3>
                        <h5>Financial Secretary</h5>
                    </div>
                    

                    <div  className="col-md-4" id="executives">
                        <img src={Ada} alt="im2" className="img-fluid"/>
                        <h3>⁠Abapiri Apadira Doris</h3>
                        <h5>General Secretary</h5>
                    </div>
                    
                    <div  className="col-md-4" id="executives">
                        <img src={Acoustic} alt="im2" className="img-fluid"/>
                        <h3>Frimpong Gideon Kwabena</h3>
                        <h5>Organizing Secretary</h5>
                    </div> 

                    <h2 className='appointees'>Appointees</h2>

                    <div  className="col-md-4" id="executives">
                        <img src={Man} alt="im2" className="img-fluid"/>
                    </div>


                    <div  className="col-md-4" id="executives">
                        <img src={woman} alt="im3" className="img-fluid"/>
                    </div>


                    <div  className="col-md-4" id="executives">
                        <img src={woman2} alt="im4" className="img-fluid"/>

                       
                    </div>

                    <div  className="col-md-4" id="executives">
                        <img src={Adubofour} alt="im4" className="img-fluid"/>

                       
                    </div>

                    <div  className="col-md-4" id="executives">
                        <img src={Man2} alt="im4" className="img-fluid"/>

                       
                    </div>

                    <div  className="col-md-4" id="patrons">
                        <img src={Man3} alt="im5" className="img-fluid"/>
                    </div>
 {/*
                    <div  className="col-md-4" id="patrons">
                        <img src={im6} alt="im6" className="img-fluid"/>
                    </div>
                */}
                </div>
            </div>
            </div>


        </div>
    )
}