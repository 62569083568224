import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './custom_css/Gallery.css'

//Images of gallery

import image1 from '../images/gallery/img_1.jpg'
import image2 from '../images/gallery/img_2.jpg'
import image3 from '../images/gallery/img_3.jpg'

import image4 from '../images/gallery/img_4.jpg'
import image5 from '../images/gallery/img_5.jpg'
import image6 from '../images/gallery/img_6.jpg'

import image7 from '../images/gallery/img_7.jpg'
import image8 from '../images/gallery/img_8.jpg'
import image9 from '../images/gallery/img_9.jpg'

import image10 from '../images/gallery/img_10.jpg'
import image11 from '../images/gallery/img_11.jpg'
import image12 from '../images/gallery/img_12.jpg'

import image13 from '../images/gallery/img_13.jpg'
import image14 from '../images/gallery/img_14.jpg'
import image15 from '../images/gallery/img_15.jpg'

import image16 from '../images/gallery/img_16.jpg'
import image17 from '../images/gallery/img_17.jpg'
import image18 from '../images/gallery/img_18.jpg'

import image19 from '../images/gallery/img_19.jpg'
import image20 from '../images/gallery/img_20.jpg'
import image21 from '../images/gallery/img_21.jpg'




export default function Gallery() {


    return(

        <div className="container">
            <h1 className="title-gallery">Gallery</h1>
        <div className="row"> 

        <div className="col-md-4">
            <img src={image1} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image2} alt="Image 1" className="img-fluid"/>
        </div>
        
        <div className="col-md-4">
            <img src={image3} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image4} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image5} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image6} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image7} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image8} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image9} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image10} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image11} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image12} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image13} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image14} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image15} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image16} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image17} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image18} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image19} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image20} alt="Image 1" className="img-fluid"/>
        </div>

        <div className="col-md-4">
            <img src={image21} alt="Image 1" className="img-fluid"/>
        </div>



    </div>
    </div>
    )
}