import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import patsaLogo from '../images/logo.svg';

//custome style sheet
import './custom_css/Navbar.css'

//React Router
import { Link } from 'react-router-dom';

export default function Navbar() {


    return(
//the styling of the nav-bar is designed with bootstrap in combination with custom css in
// ./custom_css/Navbar.css directory

      <nav className="navbar navbar-expand-lg fixed-top custom-navbar">
          <div className="container-fluid" >
              <Link  to="/"className="navbar-brand text-white" href="#">
                <img className="nav-logo"src={patsaLogo} alt="logo"/>
                <span className="navbar-brand-name">Packaging Technology</span>
              </Link>
              <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse text-center custom-navbar-nav" id="navbarNav">
                  <ul className="navbar-nav mr-auto">
                      <li className="nav-item ">
                          <Link to="/" className="nav-link active text-white" aria-current="page" href="#">Home</Link>
                      </li>
                      <li className="nav-item">
                          <Link to="/about"className="nav-link" >About</Link>
                      </li>
                      <li className="nav-item">
                          <Link to="/contact" className="nav-link" >Contact</Link>
                      </li>
                      <li className="nav-item">
                          <Link to="/Association"className="nav-link" >Association</Link>
                      </li>

                      <li className="nav-item">
                          <Link to="/gallery"className="nav-link" >Gallery</Link>
                      </li>

                      {/* these nav-links is diabled and it points to no where */}

                      <li className="nav-item">
                          <Link to="disabel" className="nav-link disabled" aria-disabled="true">Disabled</Link>
                      </li>
                   </ul>
               </div>
           </div>
        </nav>

    )
}