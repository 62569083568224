import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import Gallery from './components/Gallery';
import ContactUs from './components/ContactUs';
import FacultyAndStaff from './components/FalcultyAndStaff';
//import FacultyStaff from'./components/FacultyStaff';
import Association from './components/Association';



function App() {
  return(
    <BrowserRouter>
      <Navbar/>

      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/contact" element={<ContactUs/>}/>
        <Route path='/faculty and staff' element={<FacultyAndStaff/>}/>
        <Route path='/association' element={<Association/>}/>
      </Routes>
      
      <Footer/>
      </BrowserRouter>

  )
}

export default App;
