import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './custom_css/Footer.css'

import { Link } from 'react-router-dom';



export default function Footer(){


    return(
        
        <div className='custom-container-footer'>
        <footer className='container text-center'>
            <div className='row  pt-5'>
                <div className="col text-white">
                    <p><Link to="/"  className="Blog text-white">Home</Link></p>
                    <p><Link to="/gallery"  className="Blog text-white">Gallery</Link></p>
                    <p><Link to="/contact"  className="Blog text-white">Contact Us</Link></p>
                    <p><Link to="/about" className="Blog text-white">About US</Link></p>

                </div>
                
                <div className="col text-white">
                    
                    <p><Link to="/faculty and staff"  className="Blog text-white">Faculty/Staff</Link></p>
                    <p><Link to="/"  className="Blog text-white">Awards</Link></p>
                    <p><Link to="/"  className="Blog text-white">Sponsors</Link></p>
                    <p><Link to="/Association"  className="Blog text-white">Association</Link></p>
                </div>
            </div>
            <div className="container">

            <div className="col text-white pt-30px">
                    <p>Follow Us</p>
                    <p>+233 54 242 8157 </p>
                    <a href='#'>Email: packagingtechnology22@gmail.com</a>
                    <p>&copy;2024 packaging technology </p>
                </div>
            </div>
        </footer>
        </div>
    )
}
