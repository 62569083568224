import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

export default function PurposefulContent () {
    
    return(
        <div className="container">
            <h1 className="col pt-30 text-center" id="purpose-headen">
            BSc. Packaging Technology
            </h1>
            <p className="purpose-content">
            A Bachelor of Science (BSc.) in Packaging Technology is a specialized degree program 
            in Knust that focuses on the design, development, and manufacturing of packaging materials 
            and systems. This program combines elements of engineering, design, and business to prepare
            students for careers in the packaging industry.
            Throughout the BSc. Packaging Technology program, students learn about packaging 
            materials, technologies, sustainability, quality control, and supply chain management.
            They gain practical skills in packaging design, testing, and production processes, as 
            well as a deep understanding of industry regulations and standards.

            
            </p>
        </div>
        
    )
}